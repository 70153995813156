<template>
  <div class="from-panel" ref="panel">
    <div class="from-title">
      <el-button type="danger" @click="addGroup">新建分组</el-button>
      <el-button type="danger" @click="newProcess">新建表单</el-button>
    </div>
    <draggable :list="groups" group="group" handle=".el-icon-rank" filter=".undrag" @start="groupsSort = true"
               :options="{animation: 300 ,sort:true, scroll: true, chosenClass:'choose'}" @end="groupSort">
      <div :class="{'form-group':true, 'undrag': (group.id === 0 || group.id === undefined)}"
           v-show="group.id > 1 || group.children.length > 0 " v-for="(group, gidx) in groups" :key="gidx">
        <div class="form-group-title">
          <span>{{group.name}}</span>
          <span>/ {{group.children.length}}</span>
          <i class="el-icon-rank" title="长按拖动可对分组排序"></i>
          <div v-if="!(group.id === 0 || group.id === undefined)">
            <el-dropdown>
              <el-button style="padding: 0;color: #ff4d4f" type="text">编辑</el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="editGroup(group)">编辑</el-dropdown-item>
                <el-dropdown-item @click.native="delGroup(group)">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <draggable style="display: flex;flex-wrap: wrap;justify-content: space-around" :list="group.children" group="from" @end="groupSort" v-show="!groupsSort" filter=".undrag"
                   :options="{animation: 300, delay: 200, chosenClass:'choose', scroll: true, sort:true}">
          <div :class="{'form-group-item':true, 'undrag': item.isStop}"
               v-for="(item, index) in group.children"
               :key="index" title="长按0.5S后可拖拽表单进行排序">
            <div style="width: 100%">
                <div class="picture" :class="item.logo.icon" :style="'background: '+item.logo.background"></div>
                <div class="exceed" :title="item.formName">{{item.formName}}</div>
              <div>
                <el-button type="text" style="color: #ff4d4f" size="mini" @click="editFrom(item, group)">编辑</el-button>
                <el-button type="text" style="color: #ff4d4f;margin: 0 16px" size="mini" @click="stopFrom(item)">{{item.isStop ? '启用' : '停用'}}</el-button>
                <el-button type="text" style="color: #ff4d4f" size="mini" @click="moveFrom(item)" v-if="item.isStop">删除</el-button>
                <el-popover placement="left" trigger="click" width="400" @show="moveSelect === null" v-else>
                  <div>
                    <el-radio-group v-model="moveSelect" size="mini">
                      <el-radio :label="g.id" border v-for="g in groups" :key="g.id" v-show="g.id > 1"
                                :disabled="g.id === group.id" style="margin: 10px;width:180px">{{g.name}}</el-radio>
                    </el-radio-group>
                  </div>
                  <div style="text-align: center; margin: 20px 0 10px 0">
                    <el-button type="primary" size="mini" @click="moveFrom(item)">提交</el-button>
                  </div>
                  <el-button type="text" style="color: #ff4d4f" slot="reference" size="mini" >移动</el-button>
                </el-popover>
              </div>
            </div>
          </div>
          <div style="width: 190px;" v-for="(item, index) in 7"></div>
        </draggable>
      </div>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import {
  getFormGroups, groupItemsSort,
  getFormDetail, updateGroup, updateForm
} from '@/api/design'
import '/src/assets/styles.css';
export default {
  name: "FormsPanel",
  components: {draggable},
  data() {
    return {
      moveSelect: '',
      visible: false,
      groupsSort: false,
      groups: []
    }
  },
  created() {
    var ps=document.getElementById('sb');
    console.log(ps)
    ps.forEach((p) => {
      let len = p.getTotalLength();
      len.style.setPriority('--l',len);
    })
  },
  mounted: function () {
    this.getGroups();



  },
  methods: {
    getGroups() {
      getFormGroups().then(rsp => {
        this.groups = rsp.data
      }).catch(err => this.$message.error('获取分组异常'))
    },
    newProcess(groupId) {
      this.$store.commit("setTemplate", this.getTemplateData());
      this.$store.commit("setIsEdit", false);
      this.$router.push("/admin/design?groupId=" + groupId);
    },
    groupSort() {
      this.groupsSort = false
      groupItemsSort(this.groups).then(rsp => {
        this.$message.success(rsp.data)
        this.getGroups()
      }).catch(err => {
        this.getGroups()
        this.$message.error(err.response.data)
      })
    },
    addGroup() {
      this.$prompt('名称', '新增分组', {
        confirmButtonText: '提交',
        cancelButtonText: '取消',
        inputPattern: /^[\u4E00-\u9FA5A-Za-z0-9\\-]{1,30}$/,
        inputErrorMessage: '分组名不能为空且长度小于30',
        inputPlaceholder: '请输入分组名称'
      }).then(({value}) => {
        updateGroup({name: value}, 'post').then(rsp => {
          this.$message.success(rsp.data)
          this.getGroups()
        }).catch(err => this.$message.error(err.response.data))
      })
    },
    delGroup(group) {
      this.$confirm('删除分组并不会删除表单，表单将会被转移到 “其他” 分组，确定要删除分组 ' + group.name + '?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        updateGroup({id: group.id}, 'delete').then(rsp => {
          this.$message.success(rsp.data)
          this.getGroups()
        }).catch(err => this.$message.error(err.response.data))
      })
    },
    editGroup(group) {
      this.$prompt('请输入新的组名', '修改分组名', {
        confirmButtonText: '提交',
        cancelButtonText: '取消',
        inputPattern: /^[\u4E00-\u9FA5A-Za-z0-9\\-]{1,30}$/,
        inputErrorMessage: '分组名不能为空且长度小于30',
        inputPlaceholder: '请输入分组名',
        inputValue: group.name
      }).then(({value}) => {
        updateGroup({id: group.id, name: value}, 'put').then(rsp => {
          this.$message.success(rsp.data)
          this.getGroups()
        }).catch(err => this.$message.error(err.response.data))
      })
    },
    updateForm(item, type) {
      updateForm({templateId: item.templateId, type: type}).then(rsp => {
        this.$message.success(rsp.data)
        this.getGroups()
      }).catch(err => this.$message.error(err.response.data))
    },
    getTemplateData(data, group){
      return data
    },
    editFrom(item, group) {
      this.$router.push("/admin/design?code=" + item.formId);
    },
    stopFrom(item) {
      console.log(item)
      let tip = item.isStop ? ' 启用后将会进入 “其他” 分组，是否继续？' : ' 停用后将会被转移到 “已停用” 分组，您可以再次启用或者删除它，是否继续?';
      this.$confirm(`<span style="font-weight: bold">${item.formName}</span>` + tip, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true,
      }).then(() => {
        this.updateForm(item, (item.isStop ? 'using' : 'stop'));
      })
    },
    moveFrom(item) {
      if (item.isStop) {
        this.$confirm(`您确定要删除表单 <span style="font-weight: bold">${item.formName}</span>> 吗，删除后无法恢复，是否继续？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          dangerouslyUseHTMLString: true,
        }).then(() => {
          this.updateForm(item, 'delete');
        })
      } else {
        if (this.moveSelect === null || this.moveSelect === ''){
          this.$message.error('请选择分组')
          return;
        }
        updateForm({templateId: item.templateId, type: 'move', groupId: this.moveSelect}).then(rsp => {
          this.$message.success(rsp.data)
          this.getGroups()
          this.moveSelect = null
        }).catch(err => this.$message.error(err.response.data))
      }
    },
  }
}
</script>
<style lang="less" scoped>

  body {
    background: #ffffff !important;
  }

  .undrag {
    background: #ebecee !important;
  }

.from-panel {
  min-width: 800px;
  background: #ffffff;
  /deep/ .from-title {
    div {
      float: right;

      .el-button {
        border-radius: 15px;
      }
    }
  }

  //height: 100vh;
}

.choose {
  background: #e9ebee;
}
.picture{
  width: 48px;
  height: 48px;
  line-height: 48px;
  border-radius: 10px;
  font-size: 20px;
  color: #ffffff;
  margin: 0 auto;
}

.exceed{
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 隐藏超出的内容 */
  text-overflow: ellipsis; /* 用省略号表示被隐藏的部分 */
  max-width: 200px; /* 设置最大宽度以限制文本的显示长度 */
  margin: 10px 0;
}
.form-group {
  margin: 20px 0;
  border-radius: 10px;
  padding:20px 10px;
  background: #F5F7FA;
  .form-group-title {
    padding: 0px 10px 20px 10px;
    .el-icon-rank {
      display: none;
      cursor: move;
    }
    &:hover {
      .el-icon-rank {
        display: inline-block;
      }
    }
    div {
      display: inline-block;
      float: right;
    }

    span:first-child {
      margin-right: 5px;
      font-size: 15px;
      font-weight: bold;
    }

    span:nth-child(2) {
      color: #656565;
      font-size: small;
      margin-right: 10px;
    }

    /deep/ .el-button {
      color: #404040;
      margin-left: 20px;

      &:hover {
        color: #2b2b2b;
      }
    }
  }

  .form-group-item:nth-child(1) {
    border-top: none !important;
  }

  .form-group-item {
    color: #3e3e3e;
    font-size: 14px;
    padding:20px 20px 10px 20px;
    margin: 10px;
    width: 130px;
    min-width: 100px;
    background-color: white;
    border-radius: 12px;
    text-align: center;
  }
}

@media screen and (max-width: 1000px) {
  .desp {
    display: none !important;
  }
}

@media screen and (max-width: 800px) {
  .from-panel{
    padding: 50px 10px;
  }
}
</style>
